import React from 'react';
import {
  ColorWrapper,
  Container,
  PageContainer,
  ImageWrapper,
  TitlePage,
  PositionsList,
} from './styles';
import {Parallax} from 'react-scroll-parallax';
import bg from './images/background.jpg';
import ReactHtmlParser from 'react-html-parser';

const positions = [
  'Bartoszek Monika, Jan Szczepkowski, Centrum Rzeźby Polskiej w Orońsku, Orońsko, 2014',
  'Chrudzimska – Uhera Katarzyna, JAN SZCZEPKOWSKI, ŻYCIE I TWÓRCZOŚĆ, Urząd Miejskiw Milanówku, 2008 r.',
  'Chrudzimska-Uhera Katarzyna, Małżeństwo z rozsądku? : czyli o artystycznych dylematachi kompromisach w czasach władzy ludowej, w oparciu o działalność społeczną, wypowiedzi i projektyrzeźbiarskie Jana Szczepkowskiego z lat 1945-1964, Saeculum Christianum: pismo historyczne',
  'Chrudzimska-Uhera Katarzyna, O rzeźbiarzu (nieco) zapomnianym, Niepodległość i Pamięć 17/1 (31),257-271, 2010',
  'Chrudzimska-Uhera Katarzyna, Prof. Jan Szczepkowski i jego dzieło (1878-1964), Milanówek MiejsceMagiczne pod red. Andrzeja Pettyna, Towarzystwo Miłośników Milanówka, 2005',
  'Dubrowska Małgorzata, Zakłady brązownicze Krasnosielskiego, Wasilewskiego, Krantza (vel Kranca)i ich odlewy artystyczne, Almanach Muzealny 1, 125-144, Warszawa, 1997',
  'Ivan Meštrović / Józef Piłsudski. Historia jednego pomnika, Ewa Ziembińska, Narodowe Centrum Kultury, Warszawa 2018',
  'Koszuta Mariusz, Spacer z Walerią po letnisku Milanówek, Wydawnictwo Impress, Warszawa 2010 r.',
  'Morozowicz – Szczepkowska Maria, Z lotu ptaka, Państwowy Instytut Wydawniczy, Warszawa, 1968',
  'Nartonowicz-Kot Maria, Sprawa pomnika Tadeusza Kościuszki w okresie międzywojennym w Łodzi,Acta Universitatis Lodziensis, Folia Historica 3, 1981',
  'Orzeł polski w rozwoju dziejowym, Światowid/ 26.12.1936/ Kraków/ Rok 13, Nr 52',
  'Słownik Biograficzny Teatru Polskiego 1765-1965, PWN Warszawa, 1973',
  'Szczepkowski Jan, Wypukłe i wklęsłe. Wspomnienia, Wstęp, opracowanie i komentarz Katarzyna Chrudzimska - Uhera, Urząd Miasta Milanówka, Milanówek, 2010',
  'Więcek Natalia, Konserwacja i problematyka niestabilnego podłoża konstrukcyjnego projektu pokrywy sarkofagu Józefa Piłsudskiego na Wawelu autorstwa Jana Szczepkowskiego. Praca mag. pod kierunkiem dr hab. Janusza Smazy, Warszawa 2015/2016.',
];

const links = [
  '1944.pl',
  '95lat.bgk.pl',
  'biogramy.pl',
  'culture.pl',
  'encyklopedia.pwn.pl',
  'ipsb.nina.gov.p',
  'jednasztuka69.wordpress.com',
  'magazyn.o.pl',
  'milanowek.eu',
  'muzea.malopolska.pl',
  'opolnocywparyzu.pl',
  'pl.wikipedia.org',
  'polonika.pl',
  'um.warszawa.pl',
  'wielkahistoria.pl',
  'wilno.tvp.pl',
  'zsp-kenar.pl',
];

const BibliographyPage = ({bgColor}) => {
  return (
    <PageContainer backgroundColor={bgColor} style={{paddingBottom: 0}}>
      <Parallax disabled>
        <ImageWrapper srcBg={bg} />
      </Parallax>
      <Parallax y={[2, -50]} className="second-parallax">
        <ColorWrapper bgColor="#525252">
          <TitlePage>Bibliografia</TitlePage>
          <Container>
            <PositionsList>
              <p>Źródła informacji zawartych na stronie:</p>
            </PositionsList>
            <PositionsList>
              {positions.map((el) => (
                <li style={{textAlign: 'justify'}}>{ReactHtmlParser(el)}</li>
              ))}
            </PositionsList>
            <PositionsList>
              <p>Strony internetowe:</p>
            </PositionsList>
            <PositionsList>
              {links.map((el) => (
                <li style={{textAlign: 'justify'}}>{el}</li>
              ))}
            </PositionsList>
          </Container>
        </ColorWrapper>
      </Parallax>
    </PageContainer>
  );
};

export default BibliographyPage;
